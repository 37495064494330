<template>
  <div class="content-index stock">
    <div style="height:calc(100vh - 200px);padding-bottom:20px;overflow:auto">
      <div
        class="flex-row"
        style="margin-bottom:20px;cursor:pointer"
        @click="back"
      >
        <i class="el-icon-back" style="font-size:18px;color:#000"></i>
        <span style="padding-left:10px"
          ><span style="color:#8A9099">采购管理</span> / 新建采购进货单</span
        >
      </div>
      <div v-if="activityList.length != 0">
        <div class="flex-row" style="padding-bottom:10px">
          <span class="icon"></span><span>优惠信息</span>
        </div>
        <div v-for="(item, index) in activityList" :key="index">
          <div style="line-height:26px">
            {{ item.activityStartTime }}-{{
              item.activityEndTime
            }}下单付款的可享受：
          </div>
          <div
            style="line-height:26px"
            v-for="(detailItem, index) in item.detailList"
            :key="index"
          >
            ● {{ detailItem.name }}: {{ detailItem.content }}
          </div>
        </div>
      </div>
      <div class="flex-row" style="padding-bottom:10px;padding-top:10px">
        <span class="icon"></span><span>商品信息</span>
      </div>
      <div class="stock-top flex-between">
        <div class="flex-row">
          <span style="margin-right:15px">已选 {{ selectList.length }}个</span>
          <el-button size="medium" @click="removal">
            批量移除
          </el-button>
        </div>
        <div>
          <el-button size="medium" type="primary" @click="addCommodity">
            添加商品
          </el-button>
        </div>
      </div>
      <div class="table-box">
        <Table
          :button-type="true"
          :button-width="100"
          :current-value="formData.current"
          :header-style="headerStyle"
          :height="''"
          :select="true"
          :orientation="'center'"
          :serial-number="true"
          :table-data="tableData"
          :title-list="titleListData"
          :total="total"
          @operationClick="operationClick"
          @selectChange="handleSelectionChange"
        >
          <template #num="{ data }">
            <el-input-number
              :key="data.goodsId"
              v-model="data.num"
              :controls="false"
              :min="1"
              :max="data.maxOrderQuantity"
              size="small"
              style="width: 100px"
            />
          </template>
        </Table>
      </div>
    </div>
    <div class="close-account flex-between">
      <div>
        <span>合计:</span>
        <span style="padding-left:10px">品项数: {{ tableData.length }}</span>
        <span style="padding-left:10px">件数: {{ totalNum }}</span>
      </div>
      <div class="flex-row">
        <span>
          <div>预估金额: {{ totalPrice }}</div>
        </span>
        <span style="padding-left:20px">
          <el-button @click="cancel">
            取消
          </el-button></span
        >
        <span style="padding-left:20px">
          <el-button
            @click="saveAndSubmit"
            type="primary"
            :loading="submitLoading"
          >
            保存并提交
          </el-button></span
        >
      </div>
    </div>
    <SelectCommodity
      :dialog-visible="selectDialogVisible"
      :table-list="tableList"
      @close="closeSelectCommodity"
      @enter="enterSelectCommodity"
    />
    <EnterGathering
      :calculateData="calculate"
      :type="1"
      :dialogTableVisible="dialogTableVisible"
      @close="closeGather"
      @enter="enterGather"
    />
  </div>
</template>

<script>
import Table from "@/components/table2";
import EnterGathering from "../enterGathering";
import SelectCommodity from "../selectCommodity";
import {
  getGoodsStorePurchaseCalculate,
  getPurchaseActivity,
} from "@/api/commodity";

export default {
  name: "stock",
  components: {
    Table,
    EnterGathering,
    SelectCommodity,
  },
  data() {
    return {
      formData: {
        current: 1,
        size: 10,
      },
      tableData: [],
      titleListData: [
        {
          prop: "goodsName",
          label: "商品名称",
          width: 140,
        },
        {
          prop: "barcode",
          label: "商品条码",
          width: 120,
        },
        {
          prop: "goodsTypeDesc",
          label: "商品分类",
        },
        {
          prop: "specification",
          label: "商品规格",
        },
        {
          prop: "boxSpecification",
          label: "商品箱规",
        },
        {
          prop: "orderGoodsUnit",
          label: "订货单位",
        },
        {
          prop: "orderPrice",
          label: "订购单价",
        },

        {
          prop: "maxOrderQuantity",
          label: "订货限制",
        },
        {
          prop: "num",
          label: "订购数量",
          width: 120,
          type: "slot",
        },
      ],
      total: 0,
      headerStyle: {
        background: "#f5f7fa",
        height: "50px",
      },
      dialogTableVisible: false,
      selectDialogVisible: false,
      tableList: [],
      selectList: [],
      totalNum: 0,
      totalPrice: 0,
      activityList: [],
      submitLoading: false,
      calculate: {},
    };
  },
  watch: {
    tableData: {
      handler(list) {
        this.totalNum = 0;
        this.totalPrice = 0;
        this.tableList = [];
        list.forEach((item) => {
          if (item.num) {
            this.totalNum = this.totalNum + item.num;
            this.totalPrice =
              this.totalPrice + (item.orderPrice * 100 * item.num) / 100;
          }
          this.tableList.push({
            goodsId: item.goodsId,
            num: item.num,
          });
        });
      },
      deep: true,
    },
  },
  created() {
    this.getPurchaseActivity();
  },
  methods: {
    back() {
      this.$router.back();
    },
    // 添加商品
    addCommodity() {
      this.selectDialogVisible = true;
    },
    closeSelectCommodity(value) {
      this.selectDialogVisible = value;
    },
    enterSelectCommodity(data) {
      this.tableList = [];
      this.tableData = data.map((item) => {
        this.tableList.push({
          goodsId: item.goodsId,
          num: item.num,
        });
        item.goodsStatusDesc = item.goodsStatus == 0 ? "已下架" : "已上架";
        item.buttonList = [
          {
            name: "移除",
            size: "medium",
            type: "text",
            code: "remove",
            width: "100",
          },
        ];
        return item;
      });
      this.selectDialogVisible = false;
    },
    // 批量移除
    removal() {
      if (this.selectList.length == 0) {
        this.$message.error("请勾选需要删除的商品！");
        return;
      }
      this.$confirm("是否移除当前商品信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableList = [];
          this.tableData = this.tableData.filter((item) => {
            if (!this.selectList.includes(item.goodsId)) {
              this.tableList.push({
                goodsId: item.goodsId,
                num: item.num,
              });
              return item;
            }
          });

          this.$message({
            type: "success",
            message: "移除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移除",
          });
        });
    },
    // 点击表格中的按钮触发
    operationClick({ row, code }) {
      if (code == "remove") {
        this.$confirm("是否移除当前商品信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.tableList = [];
            this.tableData = this.tableData.filter((item) => {
              if (item.goodsId != row.goodsId) {
                this.tableList.push({
                  goodsId: item.goodsId,
                  num: item.num,
                });
                return item;
              }
            });
            this.$message({
              type: "success",
              message: "移除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消移除",
            });
          });
      }
    },
    // 勾选表格项触发
    handleSelectionChange(data) {
      this.selectList = data.map((item) => item.goodsId);
    },
    // 取消
    cancel() {
      this.back();
    },
    // 保存并提交
    async saveAndSubmit() {
      if (!this.tableData || this.tableData.length == 0) {
        this.$message.error("请添加商品！");
        return;
      }
      let bool = this.tableData.some((item) => !item.num);
      if (bool) {
        this.$message.error("请输入商品数量！");
        return;
      }
      let goodsIdList = [];
      this.tableData.forEach((item) => {
        goodsIdList.push({
          goodsId: item.goodsId,
          purchaseNum: item.num,
        });
      });
      this.submitLoading = true;
      // 付款前进行判断
      let { data } = await getGoodsStorePurchaseCalculate(goodsIdList);
      console.log(data);
      if (data.code == 0) {
        this.calculate = {
          ...data.data,
          goodsIdList: goodsIdList,
        };
        this.submitLoading = false;
        this.dialogTableVisible = true;
      } else {
        this.submitLoading = false;
        this.$message.error(data.msg);
      }
    },
    // 关闭支付弹框
    closeGather() {
      this.dialogTableVisible = false;
    },
    // 确定支付
    enterGather() {
      this.dialogTableVisible = false;
      this.tableList = [];
      this.tableData = [];
      this.$router.back();
    },
    // 获取门店采购活动
    async getPurchaseActivity() {
      let res = await getPurchaseActivity();
      this.activityList = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.stock {
  padding: 20px;
  position: relative;

  .table-box {
    margin-top: 20px;
  }

  .icon {
    width: 4px;
    height: 15px;
    border-radius: 5px;
    background-color: #3370ff;
    margin-right: 10px;
  }

  .close-account {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    padding: 0 20px;
    border-top: 1px solid #ebeef5;
    background-color: #fff;
    z-index: 99;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
