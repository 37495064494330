<template>
  <el-dialog center :show-close="false" :visible="dialogVisible" :width="width">
    <template slot="title">
      <span style="font-size: 18px">选择商品</span>
    </template>
    <div class="flex-between">
      <div class="table-box1">
        <div class="flex-row" style="height: 50px; background-color: #f7f8fa">
          <span style="padding: 0 20px 0">商品名称</span>
          <el-input
            ref="search"
            v-model.trim="goodsName"
            clearable
            placeholder="请输入商品名称"
            style="width: 180px"
          />
          <el-button
            size="small"
            style="margin-left: 10px"
            type="primary"
            @click="search"
          >
            查 询
          </el-button>
        </div>
        <Table
          ref="table1"
          :header-style="{
            height: '50px',
            background: '#FFF',
          }"
          :height="'420px'"
          :loading="tableLoading"
          :orientation="'center'"
          :page="true"
          :select="true"
          :serial-number="false"
          :table-data="tableData"
          :title-list="titleListData"
          @selectChange="handleSelectionChange"
        />
      </div>
      <div class="table-box2">
        <div
          class="flex-between"
          style="height: 50px; background-color: #f7f8fa"
        >
          <span style="padding-left: 20px">
            已选{{ tableData2.length }}个，最多可选100个
          </span>
          <span style="padding-right: 20px">
            <el-button size="medium" type="text" @click="clear">清空</el-button>
          </span>
        </div>
        <Table
          :button-type="true"
          :button-width="100"
          :header-style="{
            height: '50px',
            background: '#FFF',
          }"
          :height="'420px'"
          :orientation="'center'"
          :page="true"
          :serial-number="false"
          :table-data="tableData2"
          :title-list="titleListData2"
          @operationClick="operationClick"
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="closeDialog">取 消</el-button>
      <el-button size="medium" type="primary" @click="enter">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Table from "@/components/table2";
import { getGoodsList } from "@/api/commodity";

export default {
  components: {
    Table,
  },
  props: {
    width: {
      type: String,
      default: "1000px",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      titleListData: [
        {
          prop: "goodsName",
          label: "商品名称",
          width: 140,
        },
        {
          prop: "barcode",
          label: "规格条码",
          width: 120,
        },
        {
          prop: "orderPrice",
          label: "订购价格",
        },
        {
          prop: "specification",
          label: "已选规格",
          width: 150,
        },
      ],
      goodsName: "",
      titleListData2: [
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "orderPrice",
          label: "订购价格",
        },
      ],
      tableData2: [],
      tableLoading: false,
    };
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        this.getGoodsListData();
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      } else {
        this.tableData = [];
        this.tableData2 = [];
        this.goodsName = "";
      }
    },
  },
  methods: {
    // 获取商品列表
    getGoodsListData() {
      this.tableLoading = true;
      getGoodsList({
        goodsName: this.goodsName,
        goodsStatus: 1,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data;
            this.tableData2=[]
            this.tableList.forEach((item2) => {
               this.tableData.forEach((item) => {
                if (item2.goodsId == item.goodsId) {
                  item.num = item2.num;
                  item.buttonList = [
                    {
                      name: "删除",
                      size: "medium",
                      type: "text",
                      code: "delete",
                      width: "100",
                    },
                  ];
                 this.tableData2.push(item)
                }
              });
            });
            setTimeout(() => {
              this.$refs.table1.toggleSelection(this.tableData2);
            }, 10);
          } else {
            this.$message.error(data.msg);
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    search() {
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
      this.getGoodsListData();
    },
    closeDialog() {
      this.$emit("close", false);
    },
    enter() {
      this.$emit("enter", this.tableData2);
    },
    // 勾选表格项触发
    handleSelectionChange(data) {
      this.tableData2 = data.map((item) => {
        item.buttonList = [
          {
            name: "删除",
            size: "medium",
            type: "text",
            code: "delete",
            width: "100",
          },
        ];
        return item;
      });
    },

    clear() {
      this.$confirm("是否确定清空已选择的商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData2 = [];
          this.$refs.table1.toggleSelection();
          this.$message({
            type: "success",
            message: "清空成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空",
          });
        });
    },
    operationClick({ row, code }) {
      if (code == "delete") {
        this.$confirm("是否删除当前商品信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.tableData2 = this.tableData2.filter(
              (item) => item.goodsName == row.goodsName
            );
            this.$refs.table1.toggleSelection(
              this.tableData2 == [] ? null : this.tableData2
            );
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box1 {
  width: 560px;
  margin-right: 20px;
  border: 1px solid #edeef2;
}

.table-box2 {
  width: 380px;
  border: 1px solid #edeef2;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-table__body td.el-table__cell {
  height: 60px !important;
}

::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
</style>
